import React from "react";
import BlogCard from './BlogCard'

function BlogList({ list = [] }) {
    return (
        <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
            {list.map(item => (
                <BlogCard 
                    key={item.databaseId}
                    title={item.title} 
                    slug={item.slug} 
                    image={item.featuredImage} 
                    content={item.content}
                />
            ))}
        </div>
    )
}

export default BlogList;