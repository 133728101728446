import contentParser from 'gatsby-wpgraphql-inline-images';

/**
 * Dynamic sidebar Classes for 3 column layout, 2 column layout, and 1 column layout
 * 
 * @param {*} sidebarLeft 
 * @param {*} sidebarRight 
 */
export const getClassNames = (sidebarLeft, sidebarRight) => {
    if (sidebarLeft.itemOne && sidebarRight.itemOne)
        return {
            sidebarLeft: 'w-full md:w-3/12 xl:w-3/12',
            sidebarRight: 'w-full md:w-3/12 xl:w-3/12',
            main: 'w-full md:w-6/12 xl:w-6/12',
        }

    return {
        sidebarLeft: sidebarLeft.itemOne ? 'w-full md:w-3/12 xl:w-3/12' : undefined,
        sidebarRight: sidebarRight.itemOne ? 'w-full md:w-3/12 xl:w-3/12' : undefined,
        main: (sidebarLeft.itemOne || sidebarRight.itemOne) ? 'w-full md:w-9/12 xl:w-9/12' : 'w-full',
    }
}

/**
 * Get feature image
 * 
 * @param {*} image 
 * @param {*} wpImagePlugin 
 */
export const getImage = (image, wpImagePlugin) => {
    if (!image) return undefined
    const { altText, link } = image
    const content = `<img src="${link}" alt="${altText}" />`
    const wpContent = content ? contentParser({ content }, wpImagePlugin) : undefined;
    return wpContent
}

/**
 * Parse wordpress content
 * 
 * @param {*} pageContext 
 */
export const wpContentParse = (pageContext) => {
    const { content, acfGlobalPages } = pageContext.node
    const wpContent = content ? contentParser({ content }, pageContext.plugins.wpImage) : undefined;
    const wpRightOne = acfGlobalPages.sidebarRight && acfGlobalPages.sidebarRight.itemOne ? contentParser({ content: acfGlobalPages.sidebarRight.itemOne }, pageContext.plugins.wpImage) : undefined;
    const wpRightTwo = acfGlobalPages.sidebarRight && acfGlobalPages.sidebarRight.itemTwo ? contentParser({ content: acfGlobalPages.sidebarRight.itemTwo }, pageContext.plugins.wpImage) : undefined;
    const wpLeftOne = acfGlobalPages.sidebarLeft && acfGlobalPages.sidebarLeft.itemOne ? contentParser({ content: acfGlobalPages.sidebarLeft.itemOne }, pageContext.plugins.wpImage) : undefined;
    const wpLeftTwo = acfGlobalPages.sidebarLeft && acfGlobalPages.sidebarLeft.itemTwo ? contentParser({ content: acfGlobalPages.sidebarLeft.itemTwo }, pageContext.plugins.wpImage) : undefined;

    return {
        main: wpContent,
        leftOne: wpLeftOne,
        leftTwo: wpLeftTwo,
        rightOne: wpRightOne,
        rightTwo: wpRightTwo,
    }
}