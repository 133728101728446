import React from "react"
import PropTypes from "prop-types"
import { Link } from 'gatsby';
import { ProductImage } from './productImage'

function Product({ storeUri, id, title, slug, image, price, hardnessOptions }) {

  return (
    <article key={id} className="p-6 w-full md:w-1/2 lg:w-1/3">
      <Link
        to={`${storeUri}${slug}`}
        className="group no-underline w-full h-full flex"
      >
        <div className="bg-products rounded-lg cursor-pointer w-full overflow-hidden relative px-3 py-6 md:px-6">

          <ProductImage image={image} />

          <div className="pt-3 md:pt-6 text-center">
            <p className="text-slategray font-bold text-lg group-hover:text-primary mb-1">
              {title}
            </p>
            <p className="text-lightgray text-sm">{"$" + price.toFixed(2)}</p>
            {hardnessOptions}
          </div>
        </div>
      </Link>
    </article>
  )
}

Product.defaultProps = {
  hardnessOptions: false,
}

Product.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.object,
    price: PropTypes.number.isRequired,
    hardnessOptions: PropTypes.bool,  
}

export default Product
