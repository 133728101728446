import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"

const getSnippet = (str, len) => {
    if (str.length > len) {
        var i = str.indexOf(" ", len);
        return str.substring(0, i);
    }
    return str;
}

const extractContentSnippet = (s, max = 150) => {
    const text = s.replace(/<[^>]+>/g, '')
    if (text && text.length >= max)
        return getSnippet(text, max) + "..."
    return text;
};

const CoverImage = ({image, slug}) => {
    if (!image) return null
    const { sourceUrl, altText } = image

    return (
        <Link to={`/blog/${slug}`}>
            <Img 
                className="w-full" 
                fluid={image.imageFile.childImageSharp.fluid} 
                alt={altText} 
            />
        </Link>
    )
}

function BlogCard({ image, title, content, slug }) {
    const snippet = extractContentSnippet(content)

    return (
        <div className="rounded overflow-hidden shadow-lg">
            <CoverImage image={image} slug={slug} />
            <div className="px-6 py-4">
                <h4 className="font-bold text-xl mb-2">{title}</h4>
                <div className="text-gray-700 text-base">
                    <p dangerouslySetInnerHTML={{__html: snippet}} />
                </div>
            </div>
            <div className="px-6 pt-4 pb-2">
                <Link 
                    to={`/blog/${slug}`}
                    className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                >
                    Read More
                </Link>
            </div>
        </div>
    )
}

export default BlogCard;