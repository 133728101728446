import React from "react"
import PropTypes from "prop-types"
import Product from './product'

function ProductGrid({ products, storeUri, filterTags }) {

    const productFilter = (product) => {
        if (filterTags.length === 0)
            return true

        let found = false
        product.tags.nodes.forEach(tag => {
            const { termTaxonomyId } = tag
            if (!found)
                found = filterTags.includes(termTaxonomyId)
        })

        return found
    }

    return (
        <div className="flex flex-wrap -mx-6">
            {products.filter(productFilter).map(product => (
                <Product
                    key={product.databaseId}
                    title={product.title}
                    id={product.databaseId}
                    storeUri={storeUri}
                    slug={product.slug}
                    image={product.featuredImage}
                    price={product.acfProduct.price}
                    hardnessOptions={product.acfProduct.hardnessOptions}
                />
            ))}
        </div>
    )
}

ProductGrid.defaultProps = {
    products: [],
    filterTags: []
}

ProductGrid.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    filterTags: PropTypes.arrayOf(PropTypes.number) 
}

export default ProductGrid
